















import { Inject } from '@cds/common';
import moment from 'moment';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { NuoxiaoerService } from '@/services/nuoxiaoer-service';

@Component({
  components: {}
})
export default class commonConfig extends Vue {
  public form = {}
  public tableData: any[] = [];
  public loading: Boolean = true;

  @Inject(NuoxiaoerService) private nuoxiaoerService!: NuoxiaoerService;

  public async created(): Promise<void> {
  }

  private mounted() {
  }
}
